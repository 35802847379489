import { ChevronLeftRounded } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Logo from "src/components/LogoSign";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['notFound'])),
  },
})

export default function Fallback() {
  const router = useRouter();
  const { t } = useTranslation('notFound');

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={2}
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Logo variant="icon" width={100} height={100} />
        <Typography variant="h5" fontWeight={400}>
          {t('title')}
        </Typography>
        <Button
          onClick={() => router.back()}
          size="small"
          startIcon={<ChevronLeftRounded />}
          variant="text"
        >
          {t('goBack')}
        </Button>
      </Stack>
    </Box>
  );
}
